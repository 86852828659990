import { WidgetPriceProps } from '../../../../types/public/WidgetClientProps';

export const demoMethodsData: { installmentCount: number }[] = [
  {
    installmentCount: 4,
  },
  {
    installmentCount: 6,
  },
];

export const defaultDemoViewPrice = {
  price: 120,
  currency: 'USD',
} as const satisfies WidgetPriceProps;
