import type { Theme } from '@wix/payment-methods-banner';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../components/PaymentMethodsBannerWidget/settingsParams';
import { LogoThemeSetting } from '../types/Settings';

export const useBannerTheme = (): Theme => {
  const settings = useSettings();
  const logoTheme = settings.get(settingsParams.logoTheme) as LogoThemeSetting;

  // Banner's "theme" dark/light refers to background color (not logo color):
  // "dark" = light logo on dark bg; "light" = dark logo on light bg
  return logoTheme === LogoThemeSetting.LightOnDark ? 'dark' : 'light';
};
