import React from 'react';
import {
  useEnvironment,
  useTranslation,
  WidgetProps,
} from '@wix/yoshi-flow-editor';
import { wiredComponents } from '../wired-components';
import { WidgetRootProps } from '../../../../types/WidgetRootProps';
import { getCurrencySymbol } from '../../../../utils/currencyUtils';
import { calcInstallmentAmount } from '../../../../utils/calcInstallmentAmount';
import { WidgetContainer } from '../elements';
import { BannerLoadable } from './BannerLoadable';
import { defaultDemoViewPrice, demoMethodsData } from './config';
import { useBannerTheme } from '../../../../hooks/useBannerTheme';
import { useTextAlign } from '../../../../hooks/useTextAlign';

export const DemoWidget = (props: WidgetProps<WidgetRootProps>) => {
  const {
    price = defaultDemoViewPrice.price,
    currency = defaultDemoViewPrice.currency,
  } = props.priceProps || {};
  const theme = useBannerTheme();
  const textAlign = useTextAlign();
  const { t } = useTranslation();
  const env = useEnvironment();
  const currencySymbol = getCurrencySymbol(currency);
  const captions: string[] = demoMethodsData.map(({ installmentCount }) =>
    t('demoView.title', {
      installmentCount,
      amount: calcInstallmentAmount(price, installmentCount),
      currency: currencySymbol,
    }),
  );

  return (
    <WidgetContainer>
      <BannerLoadable
        deviceType={env.isMobile ? 'mobile' : 'desktop'}
        theme={theme}
        textAlign={textAlign}
        captions={captions}
        wiredComponents={wiredComponents}
      />
    </WidgetContainer>
  );
};
