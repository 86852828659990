import {
  AppInstance,
  RevalidateAppInstance,
} from '@wix/cashier-common/dist/src/types/AppInstance';
import { useCallback, useEffect, useRef } from 'react';
import { WidgetPlatformProps } from '../types/WidgetPlatformProps';

type ResolveAppInstance = (appInstance: AppInstance) => void;

export const useAppInstanceRevalidation = (
  props: WidgetPlatformProps,
): RevalidateAppInstance => {
  const revalidateRef = useRef<{
    promise: Promise<AppInstance>;
    resolve: ResolveAppInstance;
  } | null>();

  useEffect(() => {
    if (revalidateRef.current) {
      revalidateRef.current.resolve(props.appInstance);
      revalidateRef.current = null;
    }
  }, [props.appInstance]);

  return useCallback(() => {
    if (revalidateRef.current) {
      return revalidateRef.current.promise;
    }

    props.requestNewAppInstance();

    let resolveAppInstance: ResolveAppInstance;
    const promise = new Promise<AppInstance>((resolve) => {
      resolveAppInstance = resolve;
    });
    // @ts-expect-error
    revalidateRef.current = { promise, resolve: resolveAppInstance };

    return promise;
  }, [props.requestNewAppInstance]);
};
