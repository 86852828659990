export const DH = {
  WidgetContainer: 'bnpl-ooi-widget-container',

  Settings: {
    Tabs: `settings-tabs`,
    DesignTabButton: `settings-design-tab-button`,
    TextAlignThumbnails: `settings-design-text-align-thumbnails`,
    LogoThemeRadioButtons: `settings-design-logo-theme-buttons`,
  },
};
