import React from 'react';
import cn from 'classnames';
import { TextButton } from 'wix-ui-tpa/cssVars';
import { classes } from './styles.st.css';

export const TextButtonComponent = (
  props: React.ComponentProps<typeof TextButton>,
) => (
  <TextButton {...props} className={cn(classes.textButton, props.className)} />
);
