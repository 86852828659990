import React from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import { WidgetRootProps } from '../../../types/WidgetRootProps';
import { DemoWidget } from './Demo';
import { ProdWidget } from './widget';
import { log } from '../../../utils/logger';

export const RootWidget = (props: WidgetProps<WidgetRootProps>) => {
  log(
    `Widget rendered with:
  platformProps: %o
  clientProps: %o
  priceProps: %o
`,
    props.platformProps,
    props.clientProps,
    props.priceProps,
  );

  if (props.platformProps.isBlocks) {
    return <DemoWidget {...props} />;
  }

  if (!props.clientProps) {
    return null;
  }

  return <ProdWidget {...props} />;
};
