import {
  createStylesParams,
  StyleParamType,
  wixColorParam,
  wixFontParam,
} from '@wix/tpa-settings';

type IStylesParams = {
  bannerTextFont: StyleParamType.Font;
  bannerTextColor: StyleParamType.Color;
};

export default createStylesParams<IStylesParams>({
  bannerTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
      lineHeight: 24,
    }),
  },
  bannerTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
});
