import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';
import { LogoThemeSetting, TextAlignSetting } from '../../types/Settings';

export type ISettingsParams = {
  logoTheme: SettingsParamType.String;
  textAlign: SettingsParamType.String;
};

export default createSettingsParams<ISettingsParams>({
  logoTheme: {
    type: SettingsParamType.String,
    getDefaultValue: () => LogoThemeSetting.DarkOnLight,
  },
  textAlign: {
    type: SettingsParamType.String,
    getDefaultValue: () => TextAlignSetting.Left,
  },
});
