export enum TextAlignSetting {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export enum LogoThemeSetting {
  DarkOnLight = 'darkOnLight',
  LightOnDark = 'lightOnDark',
}
