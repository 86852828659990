import React, { useCallback, useEffect, useRef } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import { PaymentMethodsBanner } from '@wix/payment-methods-banner/lazy';
import { useBannerProps } from '../../../hooks/useBannerProps';
import { WidgetRootProps } from '../../../types/WidgetRootProps';
import { WidgetContainer } from './elements';
import { log } from '../../../utils/logger';
import { OnReadyResult } from '../../../types/public/OnReadyResult';

export const ProdWidget = (props: WidgetProps<WidgetRootProps>) => {
  const bannerProps = useBannerProps(props);
  const readyResultRef = useRef<OnReadyResult | null>(null);
  const { onReady } = props.clientProps;

  const handleFullLoad = useCallback(
    (result: OnReadyResult) => {
      log(`Widget: Banner load called with %o`, result);
      readyResultRef.current = result;
      onReady?.(result);
    },
    [onReady],
  );

  // Controller of the consuming App might get re-executed (as well as ours), and the "configure" API might get called again
  // with the new "onReady" callback. Since it's not possible to preserve the result in the controller (gets reloaded),
  // we keep it in the widget and use it to re-notify the consumer via the updated "onReady" callback.
  useEffect(() => {
    if (readyResultRef.current) {
      log(`Widget: onReady cb changed - calling it with the preserved result`);
      onReady?.(readyResultRef.current);
    }
  }, [onReady]);

  return (
    <WidgetContainer>
      <PaymentMethodsBanner {...bannerProps} onFullLoad={handleFullLoad} />
    </WidgetContainer>
  );
};
