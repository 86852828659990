import type { TextAlign } from '@wix/payment-methods-banner';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../components/PaymentMethodsBannerWidget/settingsParams';
import { TextAlignSetting } from '../types/Settings';

export const useTextAlign = (): TextAlign => {
  const settings = useSettings();

  return settings.get(settingsParams.textAlign) as TextAlignSetting;
};
